import axios from 'axios'
import endpoint from '../endpoint'
import getAuthHeaderJwt from '@/utils/getAuthHeaderJWT'

const common = {
  getAllQuestion: (params) => {
    const url = `${endpoint.testForm.getAllQuestion}/${params.id}`
    return axios.get(url, { headers: getAuthHeaderJwt() })
  },
  getAllTestForm: () => {
    const url = `${endpoint.testForm.getAllTestForm}`
    return axios.get(url, { headers: getAuthHeaderJwt() })
  },
  recordTestForm: (requestBody) => {
    const url = `${endpoint.testForm.recordTestForm}`
    return axios.post(url, requestBody, { headers: getAuthHeaderJwt() })
  },
  saveAnswer: (requestBody) => {
    const url = `${endpoint.testForm.saveAnswer}`
    return axios.post(url, requestBody, { headers: getAuthHeaderJwt() })
  },
  saveMultipleAnswer: (requestBody) => {
    const url = `${endpoint.testForm.saveMultipleAnswer}`
    return axios.post(url, requestBody, { headers: getAuthHeaderJwt() })
  },
  updateTimeTestForm: (params) => {
    const url = `${endpoint.general}/${params.do}/test-form/${params.id}`
    return axios.put(url, null, { headers: getAuthHeaderJwt() })
  },
  upload: (requestBody, params) => {
    const url = `${endpoint.testForm.upload}/${params.folder}`
    return axios.post(url, requestBody, { headers: { 'Content-type': 'multipart/form-data' } })
  },
  uploadUpdate: (requestBody, params) => {
    const url = `${endpoint.testForm.upload}/${params.folder}/${params.question_id}/${params.participant_id}`
    return axios.put(url, requestBody, { headers: { 'Content-type': 'multipart/form-data' } })
  }
}

export default common
