import api from '@/api'

const state = {}

const actions = {
  getAllQuestion: ({ _ }, { params }) => {
    return new Promise((resolve, reject) => {
      api.testForm.getAllQuestion(params).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  getAllTestForm: ({ _ }) => {
    return new Promise((resolve, reject) => {
      api.testForm.getAllTestForm().then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  recordTestForm: ({ _ }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.testForm.recordTestForm(requestBody).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  saveAnswer: ({ _ }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.testForm.saveAnswer(requestBody).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  saveMultipleAnswer: ({ _ }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.testForm.saveMultipleAnswer(requestBody).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  updateTimeTestForm: ({ _ }, { params }) => {
    return new Promise((resolve, reject) => {
      api.testForm.updateTimeTestForm(params).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  upload: ({ _ }, { requestBody, params }) => {
    return new Promise((resolve, reject) => {
      api.testForm.upload(requestBody, params).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  uploadUpdate: ({ _ }, { requestBody, params }) => {
    return new Promise((resolve, reject) => {
      api.testForm.uploadUpdate(requestBody, params).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  }
}

const mutations = {}

const getters = {}

export default { state, getters, actions, mutations }
